import React, { useState } from 'react';
import Top from '../Top/Top';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import axios from 'axios';

const SavingTrans = () => {
  const [formData, setFormData] = useState({
    txnDate: '',
    branchName: '',
    accNo: '',
    memberCode: '',
    name: '',
    mobileNumber: '',
    avaBalance: '',
    transactionFor: '',
    remarks: '',
    txnType: '',
    amount: '',
    paymentBy: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:3500/savingTransaction/66a5016b93c22ff39a1a7e71', formData);
      console.log('Form submitted successfully:', response.data);
      alert("Transaction Saved")
    } catch (error) {
      console.error('There was an error submitting the form:', error);
      alert("Failed to save transaction")
    }
  };

  return (
    <>
      <Top />
      <div className='registration'>
        <Container>
          <Form onSubmit={handleSubmit}>
            <h5>Saving Transaction Details</h5>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label>Txn Date</Form.Label>
                  <Form.Control type="date" name="txnDate" value={formData.txnDate} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Branch Name</Form.Label>
                  <Form.Control type="text" name="branchName" placeholder="Enter branch name" value={formData.branchName} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Account No.</Form.Label>
                  <Form.Control type="text" name="accNo" value={formData.accNo} onChange={handleChange} />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group>
                  <Form.Label>Member Code</Form.Label>
                  <Form.Control type="text" name="memberCode" value={formData.memberCode} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" name="name" placeholder="Enter A/C holder name" value={formData.name} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Mobile No.</Form.Label>
                  <Form.Control type="text" name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} />
                </Form.Group>
              </Col>
            </Row>

            <h5>Payment Details</h5>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label>Ava. Balance</Form.Label>
                  <Form.Control type="text" name="avaBalance" value={formData.avaBalance} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Transaction For</Form.Label>
                  <Form.Control type="text" name="transactionFor" placeholder='Enter Transaction For' value={formData.transactionFor} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Remarks</Form.Label>
                  <Form.Control type="text" name="remarks" placeholder='Enter Remark' value={formData.remarks} onChange={handleChange} />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group>
                  <Form.Label>Txn. Type</Form.Label>
                  <Form.Select name="txnType" value={formData.txnType} onChange={handleChange}>
                    <option value="">Select</option>
                    <option value="Deposit">Deposit</option>
                    <option value="Withdrawal">Withdrawal</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Enter Amount</Form.Label>
                  <Form.Control type="number" name="amount" placeholder='Enter Amount' value={formData.amount} onChange={handleChange} />
                </Form.Group>
                <Form.Group className='mb-5'>
                  <Form.Label>Payment By</Form.Label>
                  <Form.Select name="paymentBy" value={formData.paymentBy} onChange={handleChange}>
                    <option value="">Select</option>
                    <option value="Cash">Cash</option>
                    <option value="Online">Online</option>
                    <option value="Cheque">Cheque</option>
                    <option value="NEFT">NEFT</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Button variant='info' type="submit">Save</Button>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default SavingTrans;
