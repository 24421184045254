import React, { useState } from "react";
import Top from "../Top/Top";
import axios from "axios";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

const Group = () => {
  const [formData, setFormData] = useState({
    name: "",
    branch: "",
    leader: "",
    mobile: "",
    address: "",
    assignEmployee: "",
    day: "",
    time: "",
    purpose: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("http://localhost:3500/saveGroupLoan/66a5016b93c22ff39a1a7e71", formData);
      console.log("Form submitted successfully:", response.data);
      alert("Group Loan Created")
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      alert("Failed to create group loan")
    }
  };

  return (
    <>
      <Top />
      <div className="registration">
        <Container>
          <Form onSubmit={handleSubmit}>
            <Row>
              <h5>Group Loan Application</h5>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter Name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Loan Branch</Form.Label>
                  <Form.Control
                    type="text"
                    name="branch"
                    placeholder="Enter Branch"
                    value={formData.branch}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Group Leader Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="leader"
                    placeholder="Enter Leader Name"
                    value={formData.leader}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Mobile No.</Form.Label>
                  <Form.Control
                    type="text"
                    name="mobile"
                    placeholder="Enter Mobile No."
                    value={formData.mobile}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Loan Purpose</Form.Label>
                  <Form.Control
                    type="text"
                    name="purpose"
                    placeholder="Enter Loan Purpose"
                    value={formData.purpose}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label>Group Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    placeholder="Enter Address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Assign Employee</Form.Label>
                  <Form.Control
                    type="text"
                    name="assignEmployee"
                    placeholder="Enter Employee Name"
                    value={formData.assignEmployee}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Collection Day</Form.Label>
                  <Form.Control
                    type="text"
                    name="day"
                    placeholder="Enter Collection Day"
                    value={formData.day}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Collection Time</Form.Label>
                  <Form.Control
                    type="text"
                    name="time"
                    placeholder="Enter Collection Time"
                    value={formData.time}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button variant="info" type="submit">
              Save
            </Button>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default Group;
