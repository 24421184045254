import firebase from 'firebase/compat/app';

import 'firebase/compat/auth';


const firebaseConfig = {
  apiKey: "AIzaSyDfMf635MDg_MtZZGIS5AaNRRq-oEwiSrQ",
authDomain: "verify-b156d.firebaseapp.com",
   projectId: "verify-b156d",
  storageBucket: "verify-b156d.appspot.com",
  messagingSenderId: "951922618219",
  appId: "1:951922618219:web:06ac51cf59e4f72e07e62e",
  measurementId: "G-P567SLJT4K"
  };
  
  firebase.initializeApp(firebaseConfig);

  export default firebase;

// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyDfMf635MDg_MtZZGIS5AaNRRq-oEwiSrQ",
//   authDomain: "verify-b156d.firebaseapp.com",
//   projectId: "verify-b156d",
//   storageBucket: "verify-b156d.appspot.com",
//   messagingSenderId: "951922618219",
//   appId: "1:951922618219:web:06ac51cf59e4f72e07e62e",
//   measurementId: "G-P567SLJT4K"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);

// export { auth };


// import firebase from 'firebase/compat/app';

// import 'firebase/compat/auth';


// const firebaseConfig = {
//     apiKey: "AIzaSyA8jfW-O9slG-IE7NxetQTLMhQZ5kYrgLI",
//     authDomain: "react-otp-work-739ea.firebaseapp.com",
//     projectId: "react-otp-work-739ea",
//     storageBucket: "react-otp-work-739ea.appspot.com",
//     messagingSenderId: "412423293838",
//     appId: "1:412423293838:web:a87172b57436cb657e32df",
//     measurementId: "G-1GXBXS8EXP"
//   };
  
//   firebase.initializeApp(firebaseConfig);

//   export default firebase;