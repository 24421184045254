import React, { useState } from "react";
import Top from "../Top/Top";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import axios from 'axios';

const SavingAcc = () => {
  const [formData, setFormData] = useState({
    openingDate: '',
    member: '',
    relative: '',
    dob: '',
    mobile: '',
    nomiee: '',
    nomieeAge: '',
    nomieeRelation: '',
    address: '',
    district: '',
    paymentBy: '',
    branch: '',
    state: '',
    pinCode: '',
    modeOfOperation: '',
    plan: '',
    openingAmt: '',
    advisorCode: '',
    advisorName: '',
    openingFee: '',
    remarks: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:3500/savingAccount/66a5016b93c22ff39a1a7e71', formData);
      console.log('Form submitted successfully:', response.data);
      alert("Account created successfully")
    } catch (error) {
      console.error('There was an error submitting the form:', error);
      alert("Failed to create Account");
    }
  };

  return (
    <>
      <Top />
      <div className="registration">
        <Container>
          <h5>Saving Account Registration</h5>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label>Opening Date</Form.Label>
                  <Form.Control type="date" name="openingDate" value={formData.openingDate} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Member Name</Form.Label>
                  <Form.Control type="text" name="member" value={formData.member} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Relative Details</Form.Label>
                  <Form.Control type="text" name="relative" value={formData.relative} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>DOB</Form.Label>
                  <Form.Control type="date" name="dob" value={formData.dob} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Mobile No.</Form.Label>
                  <Form.Control type="text" name="mobile" value={formData.mobile} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Nomiee Name</Form.Label>
                  <Form.Control type="text" name="nomiee" value={formData.nomiee} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Member Age</Form.Label>
                  <Form.Control type="number" name="nomieeAge" value={formData.nomieeAge} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Member Relation</Form.Label>
                  <Form.Control type="text" name="nomieeRelation" value={formData.nomieeRelation} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Address</Form.Label>
                  <Form.Control type="text" name="address" value={formData.address} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>District</Form.Label>
                  <Form.Control type="text" name="district" value={formData.district} onChange={handleChange} />
                </Form.Group>
                <Form.Group className="mb-5">
                  <Form.Label>Payment By</Form.Label>
                  <Form.Select name="paymentBy" value={formData.paymentBy} onChange={handleChange}>
                    <option value="">Select</option>
                    <option value="Cash">Cash</option>
                    <option value="Cheque">Cheque</option>
                    <option value="NEFT">NEFT</option>
                    <option value="Online">Online</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group>
                  <Form.Label>Branch Name</Form.Label>
                  <Form.Control type="text" name="branch" value={formData.branch} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>State</Form.Label>
                  <Form.Control type="text" name="state" value={formData.state} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Pincode</Form.Label>
                  <Form.Control type="number" name="pinCode" value={formData.pinCode} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Mode of Operation</Form.Label>
                  <Form.Select name="modeOfOperation" value={formData.modeOfOperation} onChange={handleChange}>
                    <option value="">Select</option>
                    <option value="Single">Single</option>
                    <option value="Joint">Joint</option>
                    <option value="Survivor">Survivor</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Select Plan</Form.Label>
                  <Form.Control type="text" name="plan" value={formData.plan} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Opening Amount</Form.Label>
                  <Form.Control type="number" name="openingAmt" value={formData.openingAmt} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Advisor Code</Form.Label>
                  <Form.Control type="number" name="advisorCode" value={formData.advisorCode} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Advisor Name</Form.Label>
                  <Form.Control type="text" name="advisorName" value={formData.advisorName} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Opening Fee</Form.Label>
                  <Form.Control type="number" name="openingFee" value={formData.openingFee} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Remarks</Form.Label>
                  <Form.Control type="text" name="remarks" value={formData.remarks} onChange={handleChange} />
                </Form.Group>
              </Col>
            </Row>

            <Button variant="info" type="submit">Create</Button>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default SavingAcc;
