import React, { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import './customer.css';
import Top from '../Top/Top';

const CustomerRegistration = () => {
  const [formData, setFormData] = useState({
    name: '',
    branch: '',
    pan: '',
    mobile: '',
    pincode: '',
    state: '',
    country: '',
    address: '',
    email: '',
    annualIncome:'',
    fathersName: '',
    occupation: '',
    dob: '',
    age: '',
    doj: '',
    gender: ''
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'annualIncome' || name === 'age' ? parseFloat(value) : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
  
      const response = await axios.post('http://localhost:3500/saveCustomer/66a5016b93c22ff39a1a7e71', formData);
      console.log('Customer registered:', response.data);
      alert("Customer Registered Successfully")
    } catch (error) {
      console.error('Error registering customer:', error);
    }
  };

  return (
    <>
      <Top />
      <Container>
        <div className='registration'>
          <h5>Customer Registration</h5>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={6} className="mb-3">
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formBranch">
                  <Form.Label>Branch</Form.Label>
                  <Form.Control
                    type="text"
                    name="branch"
                    placeholder="Enter branch"
                    value={formData.branch}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formPan">
                  <Form.Label>PAN No.</Form.Label>
                  <Form.Control
                    type="text"
                    name="pan"
                    placeholder="Enter PAN No."
                    value={formData.pan}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formMobile">
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="mobile"
                    placeholder="Enter Mobile No."
                    value={formData.mobile}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId='formPincode'>
                  <Form.Label>Pincode</Form.Label>
                  <Form.Control
                    type="text" // Changed from number to text to handle leading zeros
                    name="pincode"
                    placeholder="Enter Pincode"
                    value={formData.pincode}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formState">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    name="state"
                    placeholder="Enter State"
                    value={formData.state}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formCountry">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    name="country"
                    placeholder="Enter Country"
                    value={formData.country}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    placeholder="Enter Address"
                    value={formData.address}
                    onChange={handleChange}
                    required/>
                    </Form.Group>
                  </Col>
                  <Col lg={6} className="mb-3">
                    <Form.Group controlId="formEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formFathers">
                      <Form.Label>Father's Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="fathersName"
                        placeholder="Enter Father's Name"
                        value={formData.fathersName}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formOccupation">
                      <Form.Label>Occupation</Form.Label>
                      <Form.Control
                        type="text"
                        name="occupation"
                        placeholder="Enter Occupation"
                        value={formData.occupation}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formAnnualIncome">
                      <Form.Label>Annual Income</Form.Label>
                      <Form.Control
                        type="number" // Ensure this is a number
                        name="annualIncome"
                        placeholder="Enter Income"
                        value={formData.annualIncome}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formDob">
                      <Form.Label>Date of Birth</Form.Label>
                      <Form.Control
                        type="date"
                        name="dob"
                        placeholder="Enter date of birth"
                        value={formData.dob}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formAge">
                      <Form.Label>Age</Form.Label>
                      <Form.Control
                        type="number"
                        name="age"
                        placeholder="Enter Age"
                        value={formData.age}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formDoj">
                      <Form.Label>Date of Joining</Form.Label>
                      <Form.Control
                        type="date"
                        name="doj"
                        placeholder="Enter date of joining"
                        value={formData.doj}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formGender">
                      <Form.Label>Gender</Form.Label>
                      <Form.Select
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group controlId="formMarital">
                      <Form.Label>Marital Status</Form.Label>
                      <Form.Select
                        name="marital"
                        value={formData.marital}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Marital Status</option>
                        <option value="Married">Married</option>
                    <option value="Unmarried">Unmarried</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Button variant='info' type="submit">Register</Button>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default CustomerRegistration;