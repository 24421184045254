import React, { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import './hr.css';
import Top from '../Top/Top';
const CreateEmployee = () => {
  const [formData, setFormData] = useState({
    name: '',
    employeeId: '',
    panNo: '',
    aadharNo: '',
    branch: '',
    mobileNo: '',
    pincode: '',
    state: '',
    district: '',
    country: '',
    address: '',
    fathersName: '',
    occupation: '',
    annualIncome: '',
    dob: '',
    age: '',
    doj: '',
    gender: '',
    martial: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
   
      const response = await axios.post('http://localhost:3500/saveEmployee/66a5016b93c22ff39a1a7e71', formData);
      console.log('Employee registered:', response.data);
      alert("Employee Created Successfully")
    } catch (error) {
      console.error('Error registering employee:', error);
    }
  };
  return (
    <>
      <Top />
      <Container>
        <div className='register'>
          <h5>Employee Registration</h5>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={6} className="mb-3">
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" name="name" placeholder="Enter name" onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId="formPan">
                  <Form.Label>PAN No.</Form.Label>
                  <Form.Control type="text" name="panNo" placeholder="Enter PAN No." onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId="formAadhar">
                  <Form.Label>Aadhar Number</Form.Label>
                  <Form.Control type="text" name="aadharNo" placeholder="Enter Aadhar No." onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId="formBranch">
                  <Form.Label>Branch</Form.Label>
                  <Form.Control type="text" name="branch" placeholder="Enter branch" onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId="formMobile">
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control type="text" name="mobileNo" placeholder="Enter Mobile No." onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId='formPincode'>
                  <Form.Label>Pincode</Form.Label>
                  <Form.Control type="number" name="pincode" placeholder="Enter Pincode" onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId="formState">
                  <Form.Label>State</Form.Label>
                  <Form.Control type="text" name="state" placeholder="Enter State" onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId="formDistrict">
                  <Form.Label>District</Form.Label>
                  <Form.Control type="text" name="district" placeholder="Enter District" onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId="formCountry">
                  <Form.Label>Country</Form.Label>
                  <Form.Control type="text" name="country" placeholder="Enter Country" onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId="formAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control type="text" name="address" placeholder="Enter Address" onChange={handleChange} />
                </Form.Group>
              </Col>

              <Col lg={6} className="mb-3">
                <Form.Group controlId="formCustomerId">
                  <Form.Label>Employee ID</Form.Label>
                  <Form.Control type="text" name="employeeId" placeholder="Enter employee ID" onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" name="email" placeholder="Enter email" onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId="formFathers">
                  <Form.Label>Father's Name</Form.Label>
                  <Form.Control type="text" name="fathersName" placeholder="Enter Father's Name" onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId="formOccupation">
                  <Form.Label>Occupation</Form.Label>
                  <Form.Control type="text" name="occupation" placeholder="Enter Occupation" onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId="formIncome">
                  <Form.Label>Annual Income</Form.Label>
                  <Form.Control type="text" name="annualIncome" placeholder="Enter Income" onChange={handleChange} />
                </Form.Group>
                <Form.Group controlId="formDob">
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control type="date" name="dob" placeholder="Enter date of birth" onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId="formAge">
                  <Form.Label>Age</Form.Label>
                  <Form.Control type="number" name="age" placeholder="Enter Age" onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId="formDoj">
                  <Form.Label>Date of Joining</Form.Label>
                  <Form.Control type="date" name="doj" placeholder="Enter date of joining" onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId="formGender">
                  <Form.Label>Gender</Form.Label>
                  <Form.Select name="gender" onChange={handleChange}>
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group controlId="formMartial">
                  <Form.Label>Martial Status</Form.Label>
                  <Form.Select name="martial" onChange={handleChange}>
                    <option value="">Select</option>
                    <option value="Married">Married</option>
                    <option value="Unmarried">Unmarried</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Button variant='info' type="submit">Register</Button>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default CreateEmployee;