import React, { useState } from "react";
import Top from "../Top/Top";
import axios from "axios";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import './loan.css';

const LoanPlan = () => {
  const [formData, setFormData] = useState({
    planCode: "",
    loanName: "",
    loanType: "",
    roi: "",
    emi: "",
    minAmt: "",
    maxAmt: "",
    minAge: "",
    maxAge: "",
    pFee: "",
    lFee: "",
    gst: "",
    iFee: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("http://localhost:3500/saveLoanPlan/66a5016b93c22ff39a1a7e71", formData);
      console.log("Form submitted successfully:", response.data);
      alert("Plan created successfully")
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      alert("Failed to create plan")
    }
  };

  return (
    <>
      <Top />
      <div className="register">
        <Container>
          <h5>Create Loan Plan</h5>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label>Plan Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="planCode"
                    placeholder="Enter Plan Code"
                    value={formData.planCode}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Loan Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="loanName"
                    placeholder="Enter Loan Name"
                    value={formData.loanName}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Loan Type</Form.Label>
                  <Form.Select
                    name="loanType"
                    value={formData.loanType}
                    onChange={handleChange}
                  >
                    <option value="">Select Loan Type</option>
                    <option value="Group Loan">Group Loan</option>
                    <option value="Personal Loan">Personal Loan</option>
                    <option value="Gold Loan">Gold Loan</option>
                    <option value="Other">Other</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group>
                  <Form.Label>ROI Type</Form.Label>
                  <Form.Select
                    name="roi"
                    value={formData.roi}
                    onChange={handleChange}
                  >
                    <option value="">Select ROI Type</option>
                    <option value="Flat Interest">Flat Interest</option>
                    <option value="Reducing Interest">Reducing Interest</option>
                    <option value="Rule 78">Rule 78</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group>
                  <Form.Label>EMI Type</Form.Label>
                  <Form.Select
                    name="emi"
                    value={formData.emi}
                    onChange={handleChange}
                  >
                    <option value="">Select EMI Type</option>
                    <option value="Regular">Regular</option>
                    <option value="Irregular">Irregular</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group>
                  <Form.Label>Min Amount</Form.Label>
                  <Form.Control
                    type="number"
                    name="minAmt"
                    placeholder="Enter Minimum Amount"
                    value={formData.minAmt}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Max Amount</Form.Label>
                  <Form.Control
                    type="number"
                    name="maxAmt"
                    placeholder="Enter Maximum Amount"
                    value={formData.maxAmt}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Min Age</Form.Label>
                  <Form.Control
                    type="number"
                    name="minAge"
                    placeholder="Enter Minimum Age"
                    value={formData.minAge}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Max Age</Form.Label>
                  <Form.Control
                    type="number"
                    name="maxAge"
                    placeholder="Enter Maximum Age"
                    value={formData.maxAge}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <h5>Deduction Details</h5>
            <Row>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Processing Fee</Form.Label>
                  <Form.Control
                    className="input1"
                    type="text"
                    name="pFee"
                    placeholder="Enter Processing Fee"
                    value={formData.pFee}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Legal Fee</Form.Label>
                  <Form.Control
                    className="input1"
                    type="text"
                    name="lFee"
                    placeholder="Enter Legal Fee"
                    value={formData.lFee}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>GST</Form.Label>
                  <Form.Control
                    className="input1"
                    type="text"
                    name="gst"
                    placeholder="Enter GST"
                    value={formData.gst}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Insurance Fee</Form.Label>
                  <Form.Control
                    className="input1"
                    type="text"
                    name="iFee"
                    placeholder="Enter Insurance Fee"
                    value={formData.iFee}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button variant="info" type="submit">
              Create Plan
            </Button>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default LoanPlan;
