



import React, { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import Top from '../Top/Top';

const CustomerKYC = () => {
  const [formData, setFormData] = useState({
    name: '',
    bankName: '',
    branchName: '',
    pan: '',
    address: '',
    guardian: '',
    aadhar: '',
    accountno: '',
    ifscCode: '',
    photoFile: null,
    signatureFile: null
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    try {
      const response = await axios.post('http://localhost:3500/saveCustomerKYC/66a5016b93c22ff39a1a7e71', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('KYC Submitted:', response.data);
      
      setFormData({
        name: '',
        bankName: '',
        branchName: '',
        pan: '',
        address: '',
        guardian: '',
        aadhar: '',
        accountno: '',
        ifscCode: '',
        photoFile: null,
        signatureFile: null
      });
      alert("Customer KYC Submitted Successfully")
    } catch (error) {
      console.error('Error submitting KYC:', error);
    }
  };

  return (
    <>
      <Top />
      <Container>
        <div className='registration'>
          <h5>Customer KYC Registration</h5>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={6} className="mb-3">
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formBank">
                  <Form.Label>Bank Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="bankName"
                    placeholder="Enter bank Name"
                    value={formData.bankName}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formBranch">
                  <Form.Label>Branch</Form.Label>
                  <Form.Control
                    type="text"
                    name="branchName"
                    placeholder="Enter branch"
                    value={formData.branchName}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formPan">
                  <Form.Label>PAN No.</Form.Label>
                  <Form.Control
                    type="text"
                    name="pan"
                    placeholder="Enter PAN No."
                    value={formData.pan}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    placeholder="Enter Address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formGuardian">
                  <Form.Label>Guardian Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="guardian"
                    placeholder="Enter Guardian"
                    value={formData.guardian}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={6} className="mb-3">
                <Form.Group controlId="formAadhar">
                  <Form.Label>Aadhar No.</Form.Label>
                  <Form.Control
                    type="text"
                    name="aadhar"
                    placeholder="Enter Aadhar No."
                    value={formData.aadhar}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formAcc">
                  <Form.Label>Account Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="accountno"
                    placeholder="Enter Account No."
                    value={formData.accountno}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formIfsc">
                  <Form.Label>IFSC Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="ifscCode"
                    placeholder="Enter IFSC Code"
                    value={formData.ifscCode}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formPhoto">
                  <Form.Label>Photo</Form.Label>
                  <Form.Control
                    type="file"
                    name="photoFile"
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formSignature">
                  <Form.Label>Signature File</Form.Label>
                  <Form.Control
                    type="file"
                    name="signatureFile"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button type='submit' variant='info'>Submit KYC</Button>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default CustomerKYC;