import React, { useState } from 'react';
import axios from 'axios';
import Top from '../Top/Top';
import { Button, Col, Container, Row } from 'react-bootstrap';

const MemberReport = () => {
  const [name, setName] = useState('');
  const [mobileNo, setMobileNo] = useState('');
  const [mCode, setMCode] = useState('');
  const [members, setMembers] = useState([]);

  const handleSearch = async () => {
    try {
      const response = await axios.get('http://localhost:3500/searchMember', {
        params: { name, mCode, mobileNo }
      });
      setMembers(response.data);
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  return (
    <>
      <Top/>
      <Container>
        <div className="heading">
          <h3>Member Report</h3>
        </div>
        <div className="choice">
          <Row>
            <Col lg={3}>
              <label className='lab'>Name</label>
              <br />
              <input 
                type="text" 
                className='mb-4 cusReportInput' 
                placeholder='Enter Name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
            <Col lg={3}>
              <label className='lab'>Mobile Number</label>
              <br />
              <input 
                type="number" 
                className='mb-4 cusReportInput' 
                placeholder='Enter mobile number'
                value={mobileNo}
                onChange={(e) => setMobileNo(e.target.value)}
              />
            </Col>
            <Col lg={3}>
              <label className='lab'>Member's Code</label>
              <br />
              <input 
                type="text" 
                className='mb-4 cusReportInput' 
                placeholder='Enter Member Code'
                value={mCode}
                onChange={(e) => setMCode(e.target.value)}
              />
            </Col>
            <Col lg={3}>
              <Button className="searchbtn" type="button" onClick={handleSearch}>
                Search
              </Button>
            </Col>
          </Row>
        </div>

        <div className="table-responsive">
          <table className="table-customer table-bordered">
            <thead>
              <tr>
                <th>SN</th>
                <th>M CODE</th>
                <th>M NAME</th>
                <th>DOJ</th>
                <th>ADDRESS</th>
                <th>MOBILE NUMBER</th>
                <th>EMAIL</th>
                <th>AADHAR NUMBER</th>
                <th>PAN NUMBER</th>
                <th>ADDRESS</th>
              </tr>
            </thead>
            <tbody>
              {members.map((member, index) => (
                <tr key={member._id}>
                  <td>{index + 1}</td>
                  <td>{member.mCode}</td>
                  <td>{member.name}</td>
                  <td>{new Date(member.registrationDate).toLocaleDateString()}</td>
                  <td>{member.address}</td>
                  <td>{member.mobileNo}</td>
                  <td>{member.email}</td>
                  <td>{member.aadharNo}</td>
                  <td>{member.panNo}</td>
                  <td>{member.address}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
    </>
  );
};

export default MemberReport;
