import React, { useState } from 'react';
import axios from 'axios';
import Top from '../Top/Top';
import { Button, Col, Container, Row } from 'react-bootstrap';

const EmployeeReport = () => {
  const [branch, setBranch] = useState('');
  const [name, setName] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const handleSearch = async () => {
    try {
      const response = await axios.get('http://localhost:3500/searchEmployee', {
        params: { branch, name, employeeId }
      });
      const allEmployees = response.data;
      setEmployees(allEmployees);
      setFilteredEmployees(
        allEmployees.filter(
          (employee) => employee.branch === branch && employee.employeeId === employeeId && employee.name === name
        )
      );
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  return (
    <>
      <Top />
      <Container>
        <div className="heading">
          <h3>Employee Report</h3>
        </div>
        <div className="choice">
          <Row>
            <Col lg={3}>
              <label htmlFor="branch" className='lab'>Search Bank</label>
              <br />
              <select 
                id="branch" 
                name="branch" 
                className="options"
                value={branch}
                onChange={(e) => setBranch(e.target.value)}
              >
                <option value="">Select Branch</option>
                <option value="Head Branch">Head Branch</option>
                <option value="BANEGANJ">BANEGANJ</option>
                <option value="IPPB SERVICE">IPPB SERVICE</option>
                <option value="BARPETA">BARPETA</option>
              </select>
            </Col>
            <Col lg={3}>
              <label className='lab'>Employee Name</label>
              <br />
              <input 
                type="text" 
                className='mb-4 cusReportInput' 
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
            <Col lg={3}>
              <label className='lab'>Employee ID</label><br />
              <input 
                type="text" 
                className='mb-4 cusReportInput' 
                value={employeeId}
                onChange={(e) => setEmployeeId(e.target.value)}
              />
            </Col>
            <Col lg={3}>
              <Button className="searchbtn" type="button" onClick={handleSearch}>
                Search
              </Button>
            </Col>
          </Row>
        </div>

        <div className="table-responsive">
          <table className="table-customer table-bordered">
            <thead>
              <tr>
                <th>S.N.</th>
                <th>Branch</th>
                <th>Employee ID</th>
                <th>Full Name</th>
                <th>Guardian Name</th>
                <th>Mobile Number</th>
                <th>Email</th>
                <th>Aadhar Number</th>
                <th>PAN Number</th>
                <th>Address</th>
                <th>Registration Date</th>
                <th>Created By</th>
                <th>KYC Status</th>
              </tr>
            </thead>
            <tbody>
              {filteredEmployees.map((employee, index) => (
                <tr key={employee._id}>
                  <td>{index + 1}</td>
                  <td>{employee.branch}</td>
                  <td>{employee.customerId}</td>
                  <td>{employee.name}</td>
                  <td>{employee.fathersName}</td>
                  <td>{employee.mobileNo}</td>
                  <td>{employee.email}</td>
                  <td>{employee.aadharNo}</td>
                  <td>{employee.panNo}</td>
                  <td>{employee.address}</td>
                  <td>{new Date(employee.doj).toLocaleDateString()}</td>
                  <td>{employee.createdBy}</td>
                  <td>{employee.kycStatus}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
    </>
  );
};

export default EmployeeReport;
