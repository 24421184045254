import React, { useState } from "react";
import Top from "../Top/Top";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import axios from "axios";

const Gold = () => {
  const [formData, setFormData] = useState({
    loanDate: "",
    memberId: "",
    relativeName: "",
    relativeRelation: "",
    dob: "",
    mobileNo: "",
    smsStatus: "",
    address: "",
    pinCode: "",
    branchName: "",
    loanPlanName: "",
    loanType: "",
    planTerm: "",
    loanMode: "",
    roi: "",
    loanAmount: "",
    roiType: "",
    emiAmount: "",
    loanPurpose: "",
    itemType: "",
    itemName: "",
    lockerLocation: "",
    purity: "",
    itemQty: "",
    itemWt: "",
    netWt: "",
    itemPrice: "",
    eligibleLoan:"",
    eligibleLoanAmount: "",
    netQty: "",
    totalItemWt: "",
    totalNetWt: "",
    memberCode: "",
    guarantorName: "",
    guarAddress: "",
    guarPinCode: "",
    phone: "",
    securityType: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("http://localhost:3500/saveGoldLoan/66a5016b93c22ff39a1a7e71", formData);
      console.log("Response:", response.data);
      alert("Gold Loan Submitted")
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to submit loan")
    }
  };

  return (
    <>
      <Top />
      <div className="registration">
        <Container>
          <h5>Gold Loan Registration</h5>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label>Loan Date</Form.Label>
                  <Form.Control type="date" name="loanDate" value={formData.loanDate} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Select Member</Form.Label>
                  <Form.Control type="text" name="memberId" value={formData.memberId} onChange={handleChange} />
                </Form.Group>
                <Form.Group className="mb-1">
                  <Form.Label>Relative Details</Form.Label>
                  <Form.Control type="text" name="relativeName" placeholder="Enter Relative Name" value={formData.relativeName} onChange={handleChange} />
                  </Form.Group>
                  <Form.Group>
                  <Form.Label>Relative Relation</Form.Label>
                  <Form.Control type="text" name="relativeRelation" placeholder="Enter Relation" value={formData.relativeRelation} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>DOB</Form.Label>
                  <Form.Control type="date" name="dob" value={formData.dob} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Mobile No.</Form.Label>
                  <Form.Control type="text" name="mobileNo" value={formData.mobileNo} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>SMS Status</Form.Label>
                  <Form.Control type="text" name="smsStatus" value={formData.smsStatus} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Address</Form.Label>
                  <Form.Control type="text" name="address" value={formData.address} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Pincode</Form.Label>
                  <Form.Control type="number" name="pinCode" value={formData.pinCode} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Roi</Form.Label>
                  <Form.Control type="number" name="roi" value={formData.roi} onChange={handleChange} />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group>
                  <Form.Label>Branch Name</Form.Label>
                  <Form.Control type="text" name="branchName" value={formData.branchName} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Loan Plan Name</Form.Label>
                  <Form.Control type="text" name="loanPlanName" value={formData.loanPlanName} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Loan Type</Form.Label>
                  <Form.Control type="text" name="loanType" value={formData.loanType} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Plan Term</Form.Label>
                  <Form.Control type="text" name="planTerm" value={formData.planTerm} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Loan Mode</Form.Label>
                  <Form.Control type="text" name="loanMode" value={formData.loanMode} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Loan Amount</Form.Label>
                  <Form.Control type="number" name="loanAmount" value={formData.loanAmount} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>ROI Type</Form.Label>
                  <Form.Control type="text" name="roiType" value={formData.roiType} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>EMI Amount</Form.Label>
                  <Form.Control type="number" name="emiAmount" value={formData.emiAmount} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Loan Purpose</Form.Label>
                  <Form.Control type="text" name="loanPurpose" value={formData.loanPurpose} onChange={handleChange} />
                </Form.Group>
              </Col>
            </Row>

            <h5>Gold/Silver Details</h5>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label>Item Type</Form.Label>
                  <Form.Select name="itemType" value={formData.itemType} onChange={handleChange}>
                    <option value="">Select Item Type</option>
                    <option value="Gold">Gold</option>
                    <option value="Silver">Silver</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Item Name</Form.Label>
                  <Form.Control type="text" name="itemName" value={formData.itemName} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Locker Location</Form.Label>
                  <Form.Control type="text" name="lockerLocation" value={formData.lockerLocation} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Purity</Form.Label>
                  <Form.Control type="text" name="purity" value={formData.purity} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Net Qty.</Form.Label>
                  <Form.Control type="number" name="netQty" value={formData.netQty} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Total Item Wt.</Form.Label>
                  <Form.Control type="number" name="totalItemWt" value={formData.totalItemWt} onChange={handleChange} />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group>
                  <Form.Label>Item Qty.</Form.Label>
                  <Form.Control type="number" name="itemQty" value={formData.itemQty} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Item Wt.</Form.Label>
                  <Form.Control type="number" name="itemWt" value={formData.itemWt} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Net Wt.</Form.Label>
                  <Form.Control type="number" name="netWt" value={formData.netWt} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Item Price</Form.Label>
                  <Form.Control type="number" name="itemPrice" value={formData.itemPrice} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Eligible Loan</Form.Label>
                  <Form.Control type="number" name="eligibleLoan" value={formData.eligibleLoan} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Eligible Loan</Form.Label>
                  <Form.Control type="number" name="eligibleLoanAmount" value={formData.eligibleLoanAmount} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Total Net Wt.</Form.Label>
                  <Form.Control type="number" name="totalNetWt" value={formData.totalNetWt} onChange={handleChange} />
                </Form.Group>
              </Col>
            </Row>

            <h5>Guarantor Details</h5>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label>Member Code</Form.Label>
                  <Form.Control type="text" name="memberCode" value={formData.memberCode} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Guarantor Name</Form.Label>
                  <Form.Control type="text" name="guarantorName" value={formData.guarantorName} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Address</Form.Label>
                  <Form.Control type="text" name="guarAddress" value={formData.guarAddress} onChange={handleChange} />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group>
                  <Form.Label>Pincode</Form.Label>
                  <Form.Control type="text" name="guarPinCode" value={formData.guarPinCode} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Phone</Form.Label>
                  <Form.Control type="text" name="phone" value={formData.phone} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Security Type</Form.Label>
                  <Form.Select name="securityType" value={formData.securityType} onChange={handleChange}>
                    <option value="">Select</option>
                    <option value="Loan Against Cheque">Loan Against Cheque</option>
                    <option value="Loan Against Silver">Loan Against Silver</option>
                    <option value="Loan Against Gold">Loan Against Gold</option>
                    <option value="Loan Against Deposit">Loan Against Deposit</option>
                    <option value="Any Others">Any Others</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Button variant="info" type="submit">Save</Button>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default Gold;
