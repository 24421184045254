import React, { useState } from "react";
import axios from "axios";
import Top from "../Top/Top";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

const SavingPlan = () => {
  const [formData, setFormData] = useState({
    schemeName: "",
    interest: "",
    minAmount: "",
    avgBal: "",
    lock: "",
    cardCharge: "",
    smsCharge: "",
    smsInterval: "",
    cardLimitM: "",
    cardLimitY: ""
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`http://localhost:3500/savingPlanMaster/66a5016b93c22ff39a1a7e71`, formData);
      console.log("Form submitted successfully", response.data);
      alert("Plan created Successfully")
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      alert("Error in saving data")
    }
  };

  return (
    <>
      <Top />
      <div className="registration">
        <Container>
          <h5>Saving Plan Registration</h5>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label>Scheme Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="schemeName"
                    placeholder="Enter Scheme Name"
                    value={formData.schemeName}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Annual R.O.I(%)</Form.Label>
                  <Form.Control
                    type="number"
                    name="interest"
                    placeholder="Enter Annual Interest rate"
                    value={formData.interest}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Min. Opening Amount</Form.Label>
                  <Form.Control
                    type="nummber"
                    name="minAmount"
                    placeholder="Enter min Amount"
                    value={formData.minAmount}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Montly Avg. Bal</Form.Label>
                  <Form.Control
                    type="number"
                    name="avgBal"
                    placeholder="Enter min Amount"
                    value={formData.avgBal}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Lock in Balance</Form.Label>
                  <Form.Control
                    type="text"
                    name="lock"
                    placeholder="Enter Lock in Balance"
                    value={formData.lock}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group>
                  <Form.Label>Card Charge</Form.Label>
                  <Form.Control
                    type="text"
                    name="cardCharge"
                    placeholder="Enter Card Charge"
                    value={formData.cardCharge}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>SMS Charges</Form.Label>
                  <Form.Control
                    type="text"
                    name="smsCharge"
                    placeholder="Enter sms Charges"
                    value={formData.smsCharge}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>SMS Interval</Form.Label>
                  <Form.Select
                    name="smsInterval"
                    value={formData.smsInterval}
                    onChange={handleChange}
                  >
                    <option value="">Select Interval</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Quarterly">Quarterly</option>
                    <option value="Half Yearly">Half Yearly</option>
                    <option value="Yearly">Yearly</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Card Limit Monthly</Form.Label>
                  <Form.Control
                    type="number"
                    name="cardLimitM"
                    placeholder="Enter card limit monthly"
                    value={formData.cardLimitM}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-5">
                  <Form.Label>Card total limit yearly</Form.Label>
                  <Form.Control
                    type="number"
                    name="cardLimitY"
                    placeholder="Enter card total limit"
                    value={formData.cardLimitY}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button variant="info" type="submit">Save</Button>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default SavingPlan;
