import React, { useState } from "react";
import axios from "axios";
import Top from "../Top/Top";
import { Button, Col, Container, Row } from "react-bootstrap";

const CustomerReport = () => {
  const [branch, setBranch] = useState('');
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);

  const handleSearch = async () => {
    try {
      const response = await axios.get('http://localhost:3500/searchCustomer', {
        params: { branch, name, mobile }
      });
      const allCustomers = response.data;
      setCustomers(allCustomers);
      setFilteredCustomers(
        allCustomers.filter(
          (customer) => 
            (!branch || customer.branch === branch) &&
            (!mobile || customer.mobile === mobile) &&
            (!name || customer.name.toLowerCase().includes(name.toLowerCase()))
        )
      );
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };

  return (
    <>
      <Top />
      <Container>
        <div className="heading">
          <h3>Customer Report</h3>
        </div>
        <div className="choice">
          <Row>
            <Col lg={3}>
              <label htmlFor="branch" className="lab">Search Bank</label>
              <br />
              <select 
                id="branch" 
                name="branch" 
                className="options"
                value={branch}
                onChange={(e) => setBranch(e.target.value)}
              >
                <option value="">Select Branch</option>
                <option value="Head Branch">Head Branch</option>
                <option value="BANEGANJ">BANEGANJ</option>
                <option value="IPPB SERVICE">IPPB SERVICE</option>
                <option value="BARPETA">BARPETA</option>
              </select>
            </Col>
            <Col lg={3}>
              <label className="lab">Mobile</label>
              <br />
              <input 
                type="number" 
                className="mb-4 cusReportInput"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </Col>
            <Col lg={3}>
              <label className="lab">Customer Name</label>
              <br />
              <input 
                type="text" 
                className="mb-1 cusReportInput"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
            <Col lg={3}>
              <Button className="searchbtn" type="button" onClick={handleSearch}>
                Search
              </Button>
            </Col>
          </Row>
        </div>

        <div className="table-responsive">
          <table className="table-customer table-bordered">
            <thead>
              <tr>
                <th>SN</th>
                <th>BRANCH</th>
                <th>FULL NAME</th>
                <th>ANNUAL INCOME</th>
                <th>MOBILE NUMBER</th>
                <th>EMAIL</th>
                <th>DOB</th>
                <th>FATHER'S Name</th>
                <th>ADDRESS</th>
                <th>REGISTRATION DATE</th>
                <th>OCCUPATON</th>
                <th>MARTIAL STATUS</th>
              </tr>
            </thead>
            <tbody>
              {filteredCustomers.map((customer, index) => (
                <tr key={customer._id}>
                  <td>{index + 1}</td>
                  <td>{customer.branch}</td>
                  <td>{customer.name}</td>
                  <td>{customer.annualIncome}</td>
                  <td>{customer.mobile}</td>
                  <td>{customer.email}</td>
                  <td>{new Date(customer.dob).toLocaleDateString()}</td>
                  <td>{customer.fathersName}</td>
                  <td>{customer.address}</td>
                  <td>{new Date(customer.doj).toLocaleDateString()}</td>
                  <td>{customer.occupation}</td>
                  <td>{customer.martial}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
    </>
  );
};

export default CustomerReport;
