import React, { useState } from 'react';
import Top from '../Top/Top';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';

const Personal = () => {
  const [formData, setFormData] = useState({
    loanDate: '',
    member: '',
    relativeDetails: '',
    dob: '',
    mobileNo: '',
    smsStatus: '',
    address: '',
    pinCode: '',
    branchName: '',
    loanPlanName: '',
    loanType: '',
    planTerm: '',
    loanMode: '',
    roiPercentage: '',
    loanAmount: '',
    roiType: '',
    emiAmount: '',
    loanPurpose: '',
    memberCode: '',
    name: '',
    guarAddress: '',
    guarPinCode: '',
    phone: '',
  });

  const [photoFile, setPhotoFile] = useState(null);
  const [signatureFile, setSignatureFile] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    if (e.target.name === 'photoFile') {
      setPhotoFile(e.target.files[0]);
    } else if (e.target.name === 'signatureFile') {
      setSignatureFile(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    Object.keys(formData).forEach(key => {
      data.append(key, formData[key]);
    });
    if (photoFile) {
      data.append('photoFile', photoFile);
    }
    if (signatureFile) {
      data.append('signatureFile', signatureFile);
    }
    
    try {
      const response = await axios.post('http://localhost:3500/savePersonalLoan/66a5016b93c22ff39a1a7e71', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Form submitted successfully:', response.data);
      alert("Personal Loan submitted");
    } catch (error) {
      console.error('There was an error submitting the form:', error);
      alert("Failed to submit loan");
    }
  };

  return (
    <>
      <Top />
      <Container className="mt-4 registration">
        <Form onSubmit={handleSubmit}>
          <h5>Personal Loan Application</h5>
          <Row className="mb-1">
            <Col md={6}>
              <Form.Group controlId="loanDate">
                <Form.Label>Loan Date</Form.Label>
                <Form.Control type="date" name="loanDate" value={formData.loanDate} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="searchMember">
                <Form.Label>Member</Form.Label>
                <Form.Control type="text" name="member" placeholder="Enter member name" value={formData.member} onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-1">
            <Col md={6}>
              <Form.Group controlId="relativeDetails">
                <Form.Label>Relative Details</Form.Label>
                <Form.Control type="text" name="relativeDetails" placeholder="Enter relative details" value={formData.relativeDetails} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="dob">
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control type="date" name="dob" value={formData.dob} onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-1">
            <Col md={6}>
              <Form.Group controlId="mobileNo">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control type="text" name="mobileNo" placeholder="Enter mobile number" value={formData.mobileNo} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="smsStatus">
                <Form.Label>SMS Status</Form.Label>
                <Form.Control type="text" name="smsStatus" placeholder="Enter SMS status" value={formData.smsStatus} onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-1">
            <Col md={6}>
              <Form.Group controlId="address">
                <Form.Label>Address</Form.Label>
                <Form.Control type="text" name="address" placeholder="Enter address" value={formData.address} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="pinCode">
                <Form.Label>Pin Code</Form.Label>
                <Form.Control type="text" name="pinCode" placeholder="Enter pin code" value={formData.pinCode} onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-1">
            <Col md={6}>
              <Form.Group controlId="branchName">
                <Form.Label>Branch Name</Form.Label>
                <Form.Control type="text" name="branchName" placeholder="Enter branch name" value={formData.branchName} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="loanPlanName">
                <Form.Label>Loan Plan Name</Form.Label>
                <Form.Control type="text" name="loanPlanName" placeholder="Enter loan plan name" value={formData.loanPlanName} onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-1">
            <Col md={6}>
              <Form.Group controlId="loanType">
                <Form.Label>Loan Type</Form.Label>
                <Form.Control type="text" name="loanType" placeholder="Enter loan type" value={formData.loanType} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="planTerm">
                <Form.Label>Plan Term</Form.Label>
                <Form.Control type="number" name="planTerm" placeholder="Enter plan term" value={formData.planTerm} onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-1">
            <Col md={6}>
              <Form.Group controlId="loanMode">
                <Form.Label>Loan Mode</Form.Label>
                <Form.Control type="text" name="loanMode" placeholder="Enter loan mode" value={formData.loanMode} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="roiPercentage">
                <Form.Label>ROI Percentage</Form.Label>
                <Form.Control type="number" name="roiPercentage" placeholder="Enter ROI percentage" value={formData.roiPercentage} onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-1">
            <Col md={6}>
              <Form.Group controlId="loanAmount">
                <Form.Label>Loan Amount</Form.Label>
                <Form.Control type="number" name="loanAmount" placeholder="Enter loan amount" value={formData.loanAmount} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="roiType">
                <Form.Label>ROI Type</Form.Label>
                <Form.Control as="select" name="roiType" value={formData.roiType} onChange={handleChange}>
                  <option value="">Select</option>
                  <option value="Flat Interest">Flat Interest</option>
                  <option value="Reducing Balance">Reducing Balance</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-1">
            <Col md={6}>
              <Form.Group controlId="emiAmount">
                <Form.Label>EMI Amount</Form.Label>
                <Form.Control type="number" name="emiAmount" placeholder="Enter EMI amount" value={formData.emiAmount} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="loanPurpose">
                <Form.Label>Loan Purpose</Form.Label>
                <Form.Control type="text" name="loanPurpose" placeholder="Enter loan purpose" value={formData.loanPurpose} onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-1">
            <Col md={6}>
              <Form.Group controlId="photoFile">
                <Form.Label>Photo File</Form.Label>
                <Form.Control type="file" name="photoFile" onChange={handleFileChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="signatureFile">
                <Form.Label>Signature File</Form.Label>
                <Form.Control type="file" name="signatureFile" onChange={handleFileChange} />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h5>Guarantor Details</h5>
            <Col lg={6}>
              <Form.Group controlId="memberCode">
                <Form.Label>Member Code</Form.Label>
                <Form.Control type="text" name="memberCode" value={formData.memberCode} onChange={handleChange} />
              </Form.Group>
              <Form.Group controlId="name">
                <Form.Label>Member Name</Form.Label>
                <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} />
              </Form.Group>
              <Form.Group controlId="guarAddress">
                <Form.Label>Address</Form.Label>
                <Form.Control type="text" name="guarAddress" value={formData.guarAddress} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group controlId="guarPinCode">
                <Form.Label>Pincode</Form.Label>
                <Form.Control type="number" name="guarPinCode" value={formData.guarPinCode} onChange={handleChange} />
              </Form.Group>
              <Form.Group controlId="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control type="text" name="phone" value={formData.phone} onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Container>
    </>
  );
};

export default Personal;
