import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import Top from '../Top/Top'
import axios from 'axios';

const CreateMember = () => {
  const [formData, setFormData] = useState({
    registrationDate: new Date().toISOString().split('T')[0], 
    relativeName: '',
    relativeRelation: '',
    gender: '',
    dob: '',
    martial: '',
    address: '',
    district: '',
    state: '',
    branchName: '',
    pinCode: '',
    aadharNo: '',
    panNo: '',
    mobileNo: '',
    email: '',
    occupation: '',
    education: '',
    mCode: '',
    mName: '',
    nomieeName: '',
    nomieeMobile: '',
    nomieeRelation: '',
    nomieeAge: '',
    nomieeAddress: '',
    nomieePan: '',
    nomieeKYC: '',
    nomieeKYCType: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:3500/saveMember/66a5016b93c22ff39a1a7e71', formData);
      console.log('Member registered:', response.data);
     
      setFormData({
        registrationDate: new Date().toISOString().split('T')[0],
        name: '',
        relativeName: '',
        relativeRelation: '',
        gender: '',
        dob: '',
        martial: '',
        address: '',
        district: '',
        state: '',
        branchName: '',
        pinCode: '',
        aadharNo: '',
        panNo: '',
        mobileNo: '',
        email: '',
        occupation: '',
        education: '',
        mCode: '',
        mName: '',
        nomieeName: '',
        nomieeMobile: '',
        nomieeRelation: '',
        nomieeAge: '',
        nomieeAddress: '',
        nomieePan: '',
        nomieeKYC: '',
        nomieeKYCType: ''
      });
      alert("Member Created Successfully")
    } catch (error) {
      console.error('Error registering member:', error);
    }
  };
  return (
    <>
    <Top />
      <Container>
        <div className='register'>
          <h5>Member Registration</h5>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={6} className="mb-3">
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formPan">
                  <Form.Label>PAN No.</Form.Label>
                  <Form.Control
                    type="text"
                    name="panNo"
                    placeholder="Enter PAN No."
                    value={formData.panNo}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formAadhar">
                  <Form.Label>Aadhar Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="aadharNo"
                    placeholder="Enter Aadhar No."
                    value={formData.aadharNo}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formBranch">
                  <Form.Label>Branch</Form.Label>
                  <Form.Control
                    type="text"
                    name="branchName"
                    placeholder="Enter branch"
                    value={formData.branchName}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formMobile">
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="mobileNo"
                    placeholder="Enter Mobile No."
                    value={formData.mobileNo}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId='formPincode'>
                  <Form.Label>Pincode</Form.Label>
                  <Form.Control
                    type="text"
                    name="pinCode"
                    placeholder="Enter Pincode"
                    value={formData.pinCode}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formState">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    name="state"
                    placeholder="Enter State"
                    value={formData.state}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formDistrict">
                  <Form.Label>District</Form.Label>
                  <Form.Control
                    type="text"
                    name="district"
                    placeholder="Enter District"
                    value={formData.district}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formOccupation">
                  <Form.Label>Occupation</Form.Label>
                  <Form.Control
                    type="text"
                    name="occupation"
                    placeholder="Enter Occupation"
                    value={formData.occupation}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    placeholder="Enter Address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col lg={6} className="mb-3">
                <Form.Group controlId="CustomerId">
                  <Form.Label>Customer ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="customerId"
                    placeholder="Enter customer ID"
                    value={formData.customerId}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formRelativeName">
                  <Form.Label>Relative's Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="relativeName"
                    placeholder="Enter Relative's Name"
                    value={formData.relativeName}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formRelativeRelation">
                  <Form.Label>Relative's Relation</Form.Label>
                  <Form.Control
                    type="text"
                    name="relativeRelation"
                    placeholder="Enter Relative's Relation"
                    value={formData.relativeRelation}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formDob">
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control
                    type="date"
                    name="dob"
                    placeholder="Enter date of birth"
                    value={formData.dob}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formEducation">
                  <Form.Label>Education</Form.Label>
                  <Form.Control
                    type="text"
                    name="education"
                    placeholder="Enter Education"
                    value={formData.education}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formMCode">
                  <Form.Label>M Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="mCode"
                    placeholder="Enter M Code"
                    value={formData.mCode}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formMName">
                  <Form.Label>M Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="mName"
                    placeholder="Enter M Name"
                    value={formData.mName}
                    onChange={handleChange}
                  />
                </Form.Group>
<Form.Group controlId="formGender">
                  <Form.Label>Gender</Form.Label>
                  <Form.Select
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group controlId="formMartial">
                  <Form.Label>Martial Status</Form.Label>
                  <Form.Select
                    name="martial"
                    value={formData.martial}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="Married">Married</option>
                    <option value="Unmarried">Unmarried</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col lg={12} className="mb-3">
                <h6>Nominee Details</h6>

                <Form.Group controlId="formNomineeName">
                  <Form.Label>Nominee Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="nomieeName"
                    placeholder="Enter Nominee Name"
                    value={formData.nomieeName}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formNomineeMobile">
                  <Form.Label>Nominee Mobile</Form.Label>
                  <Form.Control
                    type="text"
                    name="nomieeMobile"
                    placeholder="Enter Nominee Mobile"
                    value={formData.nomieeMobile}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formNomineeRelation">
                  <Form.Label>Nominee Relation</Form.Label>
                  <Form.Control
                    type="text"
                    name="nomieeRelation"
                    placeholder="Enter Nominee Relation"
                    value={formData.nomieeRelation}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formNomineeAge">
                  <Form.Label>Nominee Age</Form.Label>
                  <Form.Control
                    type="number"
                    name="nomieeAge"
                    placeholder="Enter Nominee Age"
                    value={formData.nomieeAge}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formNomineeAddress">
                  <Form.Label>Nominee Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="nomieeAddress"
                    placeholder="Enter Nominee Address"
                    value={formData.nomieeAddress}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formNomineePan">
                  <Form.Label>Nominee PAN</Form.Label>
                  <Form.Control
                    type="text"
                    name="nomieePan"
                    placeholder="Enter Nominee PAN"
                    value={formData.nomieePan}
                    onChange={handleChange}
                  />
                </Form.Group>

<Form.Group controlId="formNomineeKYC">
                  <Form.Label>Nominee KYC Details</Form.Label>
                  <Form.Control
                    type="text"
                    name="nomieeKYC"
                    placeholder="Enter Nominee KYC Details"
                    value={formData.nomieeKYC}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formNomineeKYCType">
                  <Form.Label>Nominee KYC Type</Form.Label>
                  <Form.Control
                    type="text"
                    name="nomieeKYCType"
                    placeholder="Enter Nominee KYC Type"
                    value={formData.nomieeKYCType}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button variant="primary" type="submit">
              Register Member
            </Button>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default CreateMember;

  